.grid-img {
    width: 100%;
    height: 25vh;
    object-fit: scale-down;
    background: rgb(0, 57, 132);
    padding: 5px;
}

.list-view-img {
    width: 100%;
    height: 30vh;
    object-fit: scale-down;
    background: rgb(0, 57, 132);
}

.grid-card {
    background: rgb(0, 57, 132);
    margin: 5px;
}